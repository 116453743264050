<template>
  <el-container class="page-container">
    <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->
     <!---->
    <!--</el-header>-->
    <el-main class="page-main" style="padding: 0 20px">
      <br>
      <el-form
              label-position="left"
              label-width="80px"
              :model="query"
              ref="searchForm"
      >
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="8" :lg="6">
            <el-input
                    placeholder="请输入内容"
                    v-model="query.searchKeyword"
                    @keyup.enter.native="doSearch"
            >
              <el-select
                      v-model="query.searchName"
                      slot="prepend"
                      placeholder="查询类型"
                      style="width: 120px;"
              >
                <el-option label="名称" value="name"></el-option>
                <el-option label="联系电话" value="mobile"></el-option>
              </el-select>
            </el-input>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="6">
            <el-form-item label-width="0">
              <el-button type="primary" @click="handleSearch">
                <i class="cw-icon cw-icon-sousuo"></i>搜索
              </el-button>
              <el-button type="primary" plain @click="clearSearch">
                <i class="cw-icon cw-icon-zhongzhi"></i>重置
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="row-btn-group">
        <el-button type="primary" @click="handleAdd('新增')">
          <i class="cw-icon cw-icon-tianjia"></i>新增
        </el-button>
      </el-row>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="code" label="编码" :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column
          prop="head_Img"
          label="LOGO"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-avatar :size="30" :src="scope.row.headImg"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column
          prop="mobile"
          label="联系电话"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
                prop="limitPrice"
                label="起送价格"
                :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="详细地址"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="spStatus"
          label="状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.spStatus == 1">营业中</span>
            <span v-if="scope.row.spStatus == 2">打烊</span>
            <span v-if="scope.row.spStatus == -1">停用</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="businessStart"
          label="营业时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-model="scope.row.businessStart">{{
              scope.row.businessStart
            }}</span>
            ~
            <span v-model="scope.row.businessEnd">{{
              scope.row.businessEnd
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="tips"
          label="宣传语"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column fixed="right" width="90" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              @click="handleEdit(scope.row)"
              plain
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.totalCount"
      >
      </el-pagination>
    </el-main>
    <!--//编辑管理员-->
    <form-dialog
      @close="handleClose('addForm')"
      title="新增"
      :dialogVisible="visibleAddDialog"
      width="800px"
      v-if="visibleAddDialog"
    >
      <template slot="content">
        <el-form
          :model="formData"
          ref="addForm"
          :rules="formRules"
          label-position="right"
          label-width="80px"
          class="storeform"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="code" label="编码">
                <el-input
                  maxlength="100"
                  placeholder="编码（系统生成）"
                  v-model="formData.code"
                  auto-complete="off"
                  tabindex="1"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="name" label="名称">
                <el-input
                  maxlength="100"
                  placeholder="名称"
                  v-model="formData.name"
                  auto-complete="off"
                  tabindex="2"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="mobile" label="联系电话">
                <el-input
                  maxlength="100"
                  placeholder="联系电话"
                  v-model="formData.mobile"
                  auto-complete="off"
                  tabindex="3"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="provinceName" label="省">
                <el-input
                  maxlength="100"
                  placeholder="省"
                  v-model="formData.provinceName"
                  auto-complete="off"
                  tabindex="4"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="cityName" label="市">
                <el-input
                  maxlength="100"
                  placeholder="市"
                  v-model="formData.cityName"
                  auto-complete="off"
                  tabindex="5"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="countyName" label="区">
                <el-input
                  maxlength="100"
                  placeholder="区"
                  v-model="formData.countyName"
                  auto-complete="off"
                  tabindex="6"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="streetName" label="街道">
                <el-input
                  maxlength="100"
                  placeholder="街道"
                  v-model="formData.streetName"
                  auto-complete="off"
                  tabindex="7"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="address" label="详细地址">
                <el-input
                  maxlength="100"
                  placeholder="详细地址"
                  v-model="formData.address"
                  auto-complete="off"
                  tabindex="8"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="longitude" label="经度">
                <el-input
                  maxlength="100"
                  placeholder="经度"
                  v-model="formData.longitude"
                  auto-complete="off"
                  tabindex="9"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="latitude" label="纬度">
                <el-input
                  maxlength="100"
                  placeholder="纬度"
                  v-model="formData.latitude"
                  auto-complete="off"
                  tabindex="10"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="spStatus" label="状态">
                <el-radio-group v-model="formData.spStatus">
                  <el-radio
                    :label="1"
                    size="mini"
                    border
                    style="margin-right: 0"
                    >正常</el-radio
                  >
                  <el-radio
                    :label="2"
                    size="mini"
                    border
                    style="margin-right: 0"
                    >打烊</el-radio
                  >
                  <el-radio
                    :label="-1"
                    size="mini"
                    border
                    style="margin-right: 0"
                    >停用</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="scope" label="配送范围">
                <el-input
                  maxlength="100"
                  placeholder="配送范围"
                  v-model="formData.scope"
                  auto-complete="off"
                  tabindex="12"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="limitPrice" label="起送价格">
                <el-input
                        placeholder="起送价格"
                        v-model="formData.limitPrice"
                        auto-complete="off"
                        tabindex="12"
                        type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item prop="businessStart" label="营业时间">
                    <el-time-select
                            placeholder="起始时间"
                            v-model="formData.businessStart"
                            :picker-options="{
                            start: '07:00',
                            step: '00:10',
                            end: '23:59'
                          }"
                            style="width: 48%"
                    >
                    </el-time-select>
                    <el-time-select
                            placeholder="结束时间"
                            v-model="formData.businessEnd"
                            :picker-options="{
                            start: '07:00',
                            step: '00:10',
                            end: '23:59',
                            minTime: formData.businessStart
                            }"
                            style="width: 48%;margin-left: 4%"
                    >
                    </el-time-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="tips" label="宣传语">
                <el-input
                  maxlength="100"
                  placeholder="宣传语"
                  v-model="formData.tips"
                  auto-complete="off"
                  tabindex="15"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="head_Img" label="头像">
                <Upload @success="handleSuccess" :imgUrl="formData.head_Img"/>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="flex flex-direction-row-reverse btn-group">
            <el-button type="primary" @click="doAdd">确 定</el-button>
            <el-button @click="closeModul">取 消</el-button>
          </div>
        </el-form>
      </template>
    </form-dialog>
    <form-dialog
      @close="handleClose('editForm')"
      title="修改"
      :dialogVisible="visibleFormDialog"
      v-if="visibleFormDialog"
    >
      <template slot="content">
        <el-form
          :model="formData"
          ref="editForm"
          :rules="formRules"
          label-position="right"
          label-width="80px"
          class="storeform"
        >
          <el-form-item prop="name" label="名称">
            <el-input maxlength="100" placeholder="名称"
                      v-model="formData.name"
                      auto-complete="off" tabindex="2" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="limitPrice" label="起送价格">
            <el-input maxlength="100" placeholder="起送价格"
                      v-model="formData.limitPrice"
                      auto-complete="off" tabindex="8" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="mobile" label="联系电话">
            <el-input maxlength="100" placeholder="联系电话"
                      v-model="formData.mobile"
                      auto-complete="off" tabindex="3" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="详细地址">
            <el-input maxlength="100" placeholder="详细地址"
                      v-model="formData.address"
                      auto-complete="off" tabindex="8" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="spStatus" label="状态">
            <el-radio-group v-model="formData.spStatus">
              <el-radio :label="1" size="mini" border>营业</el-radio>
              <el-radio :label="2" size="mini" border>打烊</el-radio>
              <el-radio :label="-1" size="mini" border>停用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item  label="营业时间" prop="businessStart">
              <el-time-select
                      placeholder="起始时间"
                      v-model="formData.businessStart"
                      :picker-options="{
                        start: '07:00',
                        step: '00:10',
                        end: '23:59'
                      }"
                      style="width: 48%"
              >
              </el-time-select>
              <el-time-select
                      placeholder="结束时间"
                      v-model="formData.businessEnd"
                      :picker-options="{
                        start: '08:00',
                        step: '00:10',
                        end: '23:59',
                        minTime: formData.businessStart
                      }"
                      style="width: 48%;margin-left: 4%"
              >
              </el-time-select>
          </el-form-item>
          <el-form-item prop="head_Img" label="头像">
            <Upload @success="handleSuccess" :imgUrl="formData.head_Img"/>
          </el-form-item>
          <el-form-item prop="tips" label="宣传语">
            <el-input maxlength="100" placeholder="宣传语"
                      v-model="formData.tips"
                      auto-complete="off" tabindex="15" type="text"></el-input>
          </el-form-item>
          <div class="flex flex-direction-row-reverse btn-group">
            <el-button type="primary" @click="doEdit">确 定</el-button>
            <el-button @click="closeModul">取 消</el-button>
          </div>
        </el-form>
      </template>
    </form-dialog>
  </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import formDialog from "../../components/formDialog";
import Upload from '../../components/upload/index'
import { foodSiteData, foodSiteAdd ,foodSiteEdit} from "../../api/storeArea";
export default {
  name: "companyManagement",
  components: { formDialog ,Upload},
  data() {
    let validateBusinessEnd = (rule, value, callback) => {
      if (!this.formData.businessEnd||!value) {
        callback(new Error('营业时间'));
      } else {
        callback();
      }
    };
    let validatemobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error('联系电话'));
      }else if((!(/^1[3456789]\d{9}$/.test(value)))){
        callback(new Error('请输入正确的联系电话'));
      } else {
        callback();
      }
    };
    let validateheadImg=(rule, value, callback) => {
      if (!this.formData.head_Img) {
        callback(new Error('头像'));
      }else {
        callback();
      }
    };
    return {
      tableData: [],
      pageInfo: {
        totalCount: 0,
        pageNumber: 1,
        pageSize: 10,
        pageOrderName: "opAt",
        pageOrderBy: "descending"
      },
      query: {
        searchName: "",
        searchKeyword: ""
      },
      visibleFormDialog: false,
      visibleAddDialog: false,
      formData: {
      },
      formRules: {
        code: [{ required: true, message: "编码系统生成", trigger: "blur" }],
        name: [{ required: true, message: "名称", trigger: "blur" }],
        // mobile: [{ required: true,validator:validatemobile,trigger: "blur" }],
        provinceName: [{ required: true, message: "省", trigger: "blur" }],
        cityName: [{ required: true, message: "市", trigger: "blur" }],
        countyName: [{ required: true, message: "区", trigger: "blur" }],
        // streetName: [{ required: true, message: "街道", trigger: "blur" }],
        // address: [{ required: true, message: "详细地址", trigger: "blur" }],
        // longitude: [{ required: true, message: "经度", trigger: "blur" }],
        // latitude: [{ required: true, message: "纬度", trigger: "blur" }],
        spStatus: [{ required: true, message: "状态", trigger: "blur" }],
        // scope: [{ required: true, message: "配送范围", trigger: "blur" }],
        businessStart: [
          { required: true, message: "营业时间",validator:validateBusinessEnd,  trigger: "blur" }
        ],
        businessEnd: [{ required: true,message: "营业时间", trigger: "blur" }],
        // tips: [{ required: true, message: "宣传语", trigger: "blur" }],
        // head_Img: [{ required: true,message: "头像",validator:validateheadImg, trigger: "change" }]
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    handleShowPassword() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
    handleSearch() {
      this.pageInfo.pageNumber = 1;
      this.foodSiteData();
    },
    async foodSiteData() {
      try {
        let query = Object.assign({}, this.pageInfo, this.query);
        let formData = {};
        for (let i in query) {
          if (query[i] !== "" && i !== "total" && query[i] !== -1) {
            formData[i] = query[i];
          }
        }
        let resonse = await foodSiteData(formData);
        let { data } = resonse;
        this.tableData = data.list;
        this.pageInfo.totalCount = data.totalCount;
      } catch (e) {
        console.log(e);
      }
    },
    clearSearch() {
      (this.query = {
        searchName: "",
        searchKeyword: ""
      }),
        this.handleSearch();
    },
    closeModul() {
      this.visibleFormDialog = false;
      this.formData={};
      this.foodSiteData();
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.foodSiteData();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNumber = val;
      this.foodSiteData();
    },
    handleClose(form) {
      this.$refs[form].resetFields();
      this.formData={};
      this.visibleFormDialog = false;
      this.visibleAddDialog = false;
    },
    handleAdd() {
      this.visibleAddDialog = true;
    },
    handleEdit(row) {
      this.visibleFormDialog = true;
      this.formData =row;
      this.formData.head_Img=row.headImg;
      console.log(574,this.formData)
      this.$forceUpdate();
    },
    async doAdd() {
      console.log(574,this.formData)
      this.$refs["addForm"].validate(async valid => {
        if (valid) {
          try {
            await foodSiteAdd(this.formData);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.visibleAddDialog = false;
            this.visibleFormDialog = false;
            this.foodSiteData();
          } catch (e) {}
        }
      });
    },
    async doEdit() {
      this.$refs["editForm"].validate(async valid => {
        if (valid) {
          try {
            await foodSiteEdit(this.formData);
            this.$message({
              type: "success",
              message: "更新成功!"
            });
            this.visibleFormDialog = false;
            this.foodSiteData();
          } catch (e) {}
        }
      });
    },
    handleSuccess(res) {
      this.formData.head_Img=res.data.url;
      this.formData.headImg=res.data.url;
      this.$forceUpdate();
    }
  },
  mounted() {
    this.foodSiteData();
  }
};
</script>

<style lang="less">
  .storeform{
    .el-upload{
      height: 50px;
      line-height: 50px;
    }
  }
.btn-group {
  button {
    margin-left: 20px;
  }
}
.el-input--prefix .el-input__inner {
  padding-left: 46px;
}
.el-input__prefix {
  left: 15px;
  color: #303133;
  i {
    font-style: normal;
  }
}

.icon-cursor {
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    color: #348eed;
  }
}

.avatar-uploader {
  .el-upload {
    border: 1px solid #dcdfe6;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 72px;
    display: block;
    &:hover {
      border-color: #409eff;
    }
  }
  .cw-icon-tupian {
    font-size: 24px;
    color: #c0c4cc;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
  }
  .avatar {
    width: 72px;
    height: 72px;
    display: block;
  }
}
</style>
